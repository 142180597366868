<template>
  <div class="flex-col page">
    <div class="flex-col">
      <div class="flex-col items-start section_1">
      </div>
      <div class="flex-col group_1">
        <div class="flex-col items-start group_2">
          <span class="text_3 text_4">ABOUT US</span>
          <span class="text_3 text_5">关于小羽佳</span>
        </div>
        <div class="flex-row group_3">
          <!-- <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16546761187357334641.png"
            class="image"
          /> -->
          <div class="w5 f18 lh35 text-l">
            <div class="text-index-2">
              厦门小羽佳家政股份有限公司（证券简称：小羽佳；证券代码：831877）成立于2010年7月14日，其前身厦门市小羽佳服务有限公司是由翟焰女士于1999年4月创立，现已发展成为一家全员合同制、全品类业务的家政服务企业。
            </div>
            <div class="text-index-2">
              经过25年的发展，公司已成为一家以服务企业和服务家庭为主线，以保洁、搬家、清洗为重点，集企业保洁、市政环卫工程、居家保洁、整理收纳、家庭搬迁、家电清洗、管道疏通、石材养护、空气净化、外墙清洗、保姆月嫂等百余种业务为一体的综合型家政互联网企业。
            </div>
            <div class="text-index-2">
              公司已于2015年2月4日正式在全国中小企业股份转让系统挂牌，成为全国第一家新三板挂牌的民营家政企业。目前公司拥有全职合同制员工600余人，累计服务客户达500万户家庭，是福建省家政领军企业，也是福建省著名商标，更是全国家庭服务业百强企业。
            </div>
          </div>
          <img
            src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16546747621564970051.png"
            class="image_1" />
        </div>
        <div class="flex-col items-start group_4">
          <span class="text_3 text_6">development course</span>
          <span class="text_3 text_7">发展历程</span>
        </div>

        <div style="width: 80%">
          <br><br>
          <el-timeline>
            <el-timeline-item timestamp="2024年" placement="top">
              <el-card>
                <h4>9 月 发布金刚到家相关技术专利</h4>
                <h4>12 月 获得 2024 年抖音平台年度行业领航奖</h4>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="2023年" placement="top">
              <el-card>
                <h4>3 月 启动全国招商运营</h4>
                <h4>8 月 全国拓展 75城 开设 100 家门店</h4>
                <h4>11 月 全国拓展 80城 开设 200家门店 </h4>
                <h4>12 月评为市级模范职工之家</h4>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="2022年" placement="top">
              <el-card>
                <h4>4月 设立全资孙公司【厦门市小羽佳养老服务有限公司】</h4>
                <h4>4月 设立全资孙公司【厦门市小羽佳商贸有限公司】</h4>
                <h4>10月 设立全资子公司【厦门市家姐文化传媒有限公司】</h4>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="2021年" placement="top">
              <el-card>
                <h4>5月 重新确立了公司未来十年的使命、愿景、价值观</h4>
                <h4>7月 成立“厦门小羽佳家政股份有限公司党支部”</h4>
                <h4>11月 30 店庆并成立“永义家政员工关怀基金”</h4>
                <h4>6-12月 开设社区门店累计设立近50家</h4>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="2020年" placement="top">
              <el-card>
                <h4>2月 公司全资子公司【厦门市小羽佳电子商务有限公司】荣获“国家
                  级高新技术企业”荣誉称号</h4>
                <h4>7月 首家社区门店开业（杏林店）</h4>
                <h4>9月 引进流程型组织变革，公司提出“以客户为中心、以奋斗者为本、
                  长期坚持艰苦奋斗、长期坚持创新变革”的理念</h4>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="2019年" placement="top">
              <el-card>
                <h4>3月 与“莲花医院”签订了品牌战略合作协议</h4>
                <h4> 4月 小羽佳20周年庆</h4>
                <h4>11月 获得“十佳诚信家政企业”荣誉称号</h4>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="2018-2019年" placement="top">
              <el-card>
                <h4>小羽佳先后筹划和落地了福州、广州业务站点</h4>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="2016年" placement="top">
              <el-card>
                <h4>1月 当选为厦门市家庭服务业协会会长单位</h4>
                <h4>6月 设立全资子公司【深圳小羽佳服务有限公司】</h4>
                <h4>10月 设立全资子公司【北京小羽佳服务有限公司】</h4>
                <h4>12月 获得“福建省示范性家政服务站”</h4>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="2015年" placement="top">
              <el-card>
                <h4>2月 小羽佳在“新三板”挂牌</h4>
                <h4>8月 小羽佳家政自动化派单云平台 3.0 版上线</h4>
                <h4>厦门小羽佳家政股份有限公司员工手册 —— 2023 版</h4>
                <h4>12月 荣获“福建家庭服务业领军企业”称号</h4>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="2013年" placement="top">
              <el-card>
                <h4>1月 被评为福建省著名商标</h4>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="2012年" placement="top">
              <el-card>
                <h4>8月 被评为“全国家庭服务业百强”</h4>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="2011年" placement="top">
              <el-card>
                <h4>5月 成为中国家庭服务业协会会员</h4>
                <h4>10月 荣获 2010-2011 年度“全国家庭服务业先进单位”</h4>
                <h4>11月 通过国家商务部商业特许经营备案</h4>
                <h4>11月 荣获厦门市“巾帼文明岗”称号</h4>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="2010年" placement="top">
              <el-card>
                <h4>5月 被评为厦门市家政服务系建设首批龙头企业</h4>
                <h4>6月 成为“海尔集团”厦门区域指定售后服务商</h4>
                <h4>11月 倡导成立厦门市家庭服务业协会，并当选为副会长单位</h4>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="2009年" placement="top">
              <el-card>
                <h4>6月 被评为厦门市著名商标</h4>
                <h4>8月 通过 ISO 国际质量管理及 ISO 国际环境管理体系认证</h4>
                <h4>9月 公司当选为福建省家庭服务业协会副会长单位</h4>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="2007年" placement="top">
              <el-card>
                <h4>9月 被厦门市思明区文明办、思明区私营企业协会联合授予“文明企
                  业”称号</h4>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="2004年" placement="top">
              <el-card>
                <h4>9月 被厦门市消委会、思明区消委会联合授予“诚信企业先进单位”</h4>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="2003年" placement="top">
              <el-card>
                <h4>7月 入选厦门思明区个体私营企业协会代表并出任常务理事</h4>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="2002年" placement="top">
              <el-card>
                <h4>6月 “小羽佳及图”顺利通过国家商标局认证</h4>
              </el-card>
            </el-timeline-item>
            <el-timeline-item timestamp="1999年" placement="top">
              <el-card>
                <h4>4月 小羽佳成立</h4>
              </el-card>
            </el-timeline-item>
          </el-timeline>
        </div>
      </div>
      <!--      <img-->
      <!--        src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16546750376657502817.png"-->
      <!--        class="image_2"-->
      <!--      />-->
    </div>
    <div class="flex-col group_5">
      <span class="text_3 text_8">价值观</span>
      <div class="justify-between group_6">
        <span class="text_3 text_9">以客户为中心</span>
        <span class="text_3 text_10">因为尊重，所以快乐</span>
      </div>
      <div class="flex-col group_7">
        <div class="justify-between group_8">
          <span class="text_3 text_11">此刻我就是小羽佳</span>
          <span class="text_3 text_12">让家人的笑声不断</span>
        </div>
        <div class="justify-between group_9">
          <div class="flex-col items-center group_10">
            <img
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16545947329619674606.png"
              class="image_3" />
            <img
              src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16545922517874613290.png"
              class="image_4" />
          </div>
          <div class="flex-col items-start group_11">
            <span class="text_3 text_13">愿景</span>
            <span class="text_3 text_14">到2030年，开设5000家社区门店</span>
            <span class="text_3 text_15">为一千万人提供日均超1万次的入户服务</span>
            <span class="text_3 text_16">为6万人提供就业机会</span>
            <span class="text_3 text_17">成为市值超百亿企业</span>
            <span class="text_3 text_18">造就一百个千万富翁</span>
          </div>
        </div>
      </div>
      <span class="text_3 text_19">使命</span>
      <span class="text_3 text_20">成就美好家庭</span>
      <div class="flex-col items-start group_12">
        <span class="text_3 text_21">WORK ENVIRONMENT</span>
        <span class="text_3 text_22">办公环境</span>
      </div>
      <div class="flex-row group_3">
        <img src="https://xyj-pic.oss-cn-shenzhen.aliyuncs.com/qiye1655366154670001.jpg" class="image" />
        <img src="https://xyj-pic.oss-cn-shenzhen.aliyuncs.com/qiye1655366179041002.jpg" class="image_1" />
      </div>
      <div class="flex-row group_3">
        <img src="https://xyj-pic.oss-cn-shenzhen.aliyuncs.com/qiye1655366186873003.jpg" class="image" />
        <img src="https://xyj-pic.oss-cn-shenzhen.aliyuncs.com/qiye1655366193374004.jpg" class="image_1" />
      </div>
      <div class="flex-col items-start group_12">
        <span class="text_3 text_21">CONTACT US</span>
        <span class="text_3 text_22">联系我们</span>
      </div>
      <div class="justify-between group_13">
        <div class="flex-row group_14">
          <div class="flex-col group_15">
            <div class="flex-col">
              <div class="flex-row group_17">
                <img
                  src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16546743342820070549.png"
                  class="image_5" />
                <span class="text_3 text_23">客户服务</span>
              </div>
              <span class="text_3 text_24">400-6655-888</span>
            </div>
            <div class="flex-col group_18">
              <div class="flex-row group_19">
                <img
                  src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16546743342848337997.png"
                  class="image_6" />
                <span class="text_3 text_25">人才招聘</span>
              </div>
              <span class="text_26">zhaopin@xiaoyujia.com</span>
            </div>
          </div>
          <div class="flex-col group_20">
            <div class="flex-col items-start group_21">
              <div class="flex-col items-start group_22">
                <img
                  src="https://project-user-resource-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16546745717550226452.png"
                  class="image_7" />
                <span class="text_27">franchise@xiaoyujia.com</span>
              </div>
              <span class="text_28">品牌合作</span>
            </div>
            <div class="flex-col group_21 view_1">
              <div class="flex-col items-start group_23">
                <img
                  src="https://project-user-resource-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16546745717551790870.png"
                  class="image_8" />
                <span class="text_29">厦门市思明区人才中心A栋6楼601</span>
              </div>
              <span class="text_30">联系地址</span>
            </div>
          </div>
        </div>
        <div class="flex-col items-center group_24">
          <img src="https://xyj-public-static.obs.cn-east-3.myhuaweicloud.com/UISCK/wwwcode.png" class="image_9" />
          <span class="text_31">扫一扫 了解更多</span>
        </div>
      </div>
    </div>
    <div class="lh100 text-c">@1999-2025 www.xiaoyujia.com 厦门小羽佳家政股份有限公司 <a
        href="https://beian.miit.gov.cn/#/Integrated/index">闽ICP备11006005号-1</a></div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {
    document.title = "小羽佳-关于我们";
  },
  methods: {},
};
</script>

<style scoped lang="css">
.text_3 {
  text-transform: uppercase;
}

.group_21 {
  position: relative;
}

.page {
  /* padding-bottom: 7.6vw; */
  background-color: #f1f2f2;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.group_5 {
  margin-top: 6.46vw;
  padding: 0 6.61vw;
}

.section_1 {
  padding: 0.5vw 3.56vw 29.92vw;
  background-image: url('https://xyj-public-static.obs.cn-east-3.myhuaweicloud.com/publicImage/1739511979092wwwHome.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.group_1 {
  margin-top: 4.74vw;
  padding-left: 6.88vw;
  padding-right: 2.19vw;
}

.image_2 {
  margin-top: 3.18vw;
  width: 100vw;
  height: 51.3542vw;
}

.text_8 {
  margin-right: 28.91vw;
  align-self: flex-end;
  color: #282828;
  font-size: 2.5vw;
  font-family: Source Han Sans CN;
  font-weight: 700;
  line-height: 2.4vw;
  letter-spacing: 0.1vw;
}

.group_6 {
  margin-top: 1.25vw;
  align-self: flex-end;
  width: 36.15vw;
}

.group_7 {
  margin-top: 2.6vw;
}

.text_19 {
  margin-right: 31.51vw;
  margin-top: 2.71vw;
  align-self: flex-end;
  color: #282828;
  font-size: 2.5vw;
  font-family: Source Han Sans CN;
  font-weight: 700;
  line-height: 2.4vw;
  letter-spacing: 0.1vw;
}

.text_20 {
  margin-right: 26.88vw;
  margin-top: 1.41vw;
  align-self: flex-end;
  color: #282828;
  font-size: 1.56vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.46vw;
  letter-spacing: 0.063vw;
}

.group_12 {
  margin-top: 7.08vw;
  align-self: flex-start;
}

.group_13 {
  margin-top: 3.13vw;
}

.text {
  color: #282828;
  font-size: 2.76vw;
  font-family: Source Han Sans CN;
  font-weight: 700;
  line-height: 2.66vw;
  letter-spacing: 0.21vw;
}

.text_1 {
  margin-top: 2.03vw;
  color: #ffcb05;
  font-size: 1.56vw;
  font-family: Source Han Sans CN;
  line-height: 1.46vw;
  letter-spacing: 0.12vw;
}

.text-wrapper {
  margin-top: 2.66vw;
  padding: 0.52vw 0 0.47vw;
  background-color: #ffdd00;
  border-radius: 0.96vw;
  width: 6.82vw;
}

.group_2 {
  align-self: flex-start;
}

.group_3 {
  margin-top: 1.09vw;
  padding-left: 0.73vw;
}

.group_4 {
  margin-top: 2.35vw;
  align-self: flex-start;
}

.text_9 {
  color: #282828;
  font-size: 1.56vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.46vw;
  letter-spacing: 0.063vw;
}

.text_10 {
  color: #282828;
  font-size: 1.56vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.51vw;
  letter-spacing: 0.063vw;
}

.group_8 {
  margin-right: 1.93vw;
  align-self: flex-end;
  width: 34.58vw;
}

.group_9 {
  margin-top: 0.42vw;
}

.text_21 {
  color: #ffcb05;
  font-size: 1.88vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.46vw;
  letter-spacing: 0.075vw;
}

.text_22 {
  margin-top: 0.68vw;
  color: #ffcb05;
  font-size: 1.15vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.09vw;
}

.group_14 {
  margin-top: 0.36vw;
  width: 39.17vw;
}

.group_24 {
  margin-right: 13.59vw;
  margin-bottom: 0.47vw;
}

.text_2 {
  color: #ffffff;
  font-size: 1.04vw;
  font-family: Source Han Sans CN;
  line-height: 0.99vw;
  letter-spacing: 0.08vw;
}

.text_4 {
  color: #ffcb05;
  font-size: 1.88vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.46vw;
  letter-spacing: 0.075vw;
}

.text_5 {
  margin-top: 0.68vw;
  color: #ffcb05;
  font-size: 1.15vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.09vw;
}

.image {
  flex: 1 1 auto;
  align-self: center;
  width: 49.27vw;
  height: 23.39vw;
}

.image_1 {
  margin-left: 2.34vw;
  flex-shrink: 0;
  width: 38.59vw;
  height: 25.73vw;
}

.text_6 {
  color: #ffcb05;
  font-size: 1.88vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.46vw;
  letter-spacing: 0.075vw;
}

.text_7 {
  margin-top: 0.68vw;
  color: #ffcb05;
  font-size: 1.15vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.09vw;
}

.text_11 {
  color: #282828;
  font-size: 1.56vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.46vw;
  letter-spacing: 0.063vw;
}

.text_12 {
  color: #282828;
  font-size: 1.56vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.46vw;
  letter-spacing: 0.063vw;
}

.group_10 {
  padding-top: 10.47vw;
  width: 26.88vw;
  height: 24.69vw;
  position: relative;
}

.group_11 {
  margin-right: 8.02vw;
  margin-top: 5vw;
}

.group_15 {
  margin: 1.2vw 0 0.99vw;
  width: 14.9vw;
}

.group_20 {
  margin-left: 1.98vw;
  width: 22.29vw;
}

.image_9 {
  width: 12.19vw;
  height: 12.24vw;
}

.text_31 {
  margin-top: 1.41vw;
  color: #808285;
  font-size: 1.61vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.51vw;
  letter-spacing: 0.065vw;
}

.image_3 {
  width: 26.88vw;
  height: 14.22vw;
}

.image_4 {
  width: 15.94vw;
  height: 22.34vw;
  position: absolute;
  left: 3.28vw;
  top: 0;
}

.text_13 {
  color: #282828;
  font-size: 2.5vw;
  font-family: Source Han Sans CN;
  font-weight: 700;
  line-height: 2.29vw;
  letter-spacing: 0.1vw;
}

.text_14 {
  margin-top: 1.41vw;
  color: #282828;
  font-size: 1.56vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.51vw;
  letter-spacing: 0.063vw;
}

.text_15 {
  margin-top: 1.61vw;
  color: #282828;
  font-size: 1.56vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.46vw;
  letter-spacing: 0.063vw;
}

.text_16 {
  margin-top: 1.67vw;
  color: #282828;
  font-size: 1.56vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.46vw;
  letter-spacing: 0.063vw;
}

.text_17 {
  margin-top: 1.72vw;
  color: #282828;
  font-size: 1.56vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.46vw;
  letter-spacing: 0.063vw;
}

.text_18 {
  margin-top: 1.67vw;
  color: #282828;
  font-size: 1.56vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.46vw;
  letter-spacing: 0.063vw;
}

.group_18 {
  margin-top: 4.69vw;
}

.view_1 {
  margin-top: 1.41vw;
}

.group_17 {
  padding: 0 0.31vw;
}

.text_24 {
  margin-top: 0.68vw;
  align-self: flex-start;
  color: #808285;
  font-size: 1.25vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 0.99vw;
}

.group_19 {
  padding-bottom: 0.83vw;
}

.text_26 {
  color: #808285;
  font-size: 1.25vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.3vw;
}

.group_22 {
  width: 18.8vw;
  position: relative;
}

.text_28 {
  color: #282828;
  font-size: 1.25vw;
  font-family: Source Han Sans CN;
  line-height: 1.15vw;
  text-transform: uppercase;
  position: absolute;
  left: 6.15vw;
  top: 50%;
  transform: translateY(-50%);
}

.group_23 {
  width: 22.29vw;
  position: relative;
}

.text_30 {
  color: #282828;
  font-size: 1.25vw;
  font-family: Source Han Sans CN;
  line-height: 1.2vw;
  text-transform: uppercase;
  position: absolute;
  left: 6.25vw;
  top: 50%;
  transform: translateY(-50%);
}

.image_5 {
  width: 2.19vw;
  height: 2.29vw;
}

.text_23 {
  margin-left: 0.78vw;
  margin-top: 1.09vw;
  color: #282828;
  font-size: 1.25vw;
  font-family: Source Han Sans CN;
  line-height: 1.2vw;
}

.image_6 {
  width: 2.71vw;
  height: 2.29vw;
}

.text_25 {
  margin: 1.09vw 6.88vw 0 0.31vw;
  color: #282828;
  font-size: 1.25vw;
  font-family: Source Han Sans CN;
  line-height: 1.2vw;
}

.image_7 {
  width: 5.31vw;
  height: 6.93vw;
}

.text_27 {
  color: #808285;
  font-size: 1.25vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.35vw;
  position: absolute;
  right: 0;
  bottom: 1.41vw;
}

.image_8 {
  width: 5.21vw;
  height: 6.93vw;
}

.text_29 {
  color: #808285;
  font-size: 1.25vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.15vw;
  position: absolute;
  right: 0;
  bottom: 1.2vw;
}
</style>
