<template>
  <div class="flex-col page">
    <div class="flex-col items-start section_1">
      <div class="flex-col items-start">
      </div>
    </div>
    <div class="flex-row group_1">
      <el-row>
        <el-tabs v-model="typeName" @tab-click="changeTag" class="tabs">
          <!-- <el-tab-pane label="全部" name="all"></el-tab-pane> -->
          <el-tab-pane :label="item" :name="item" v-for="(item, index) in typeNameList" :key="index"></el-tab-pane>
        </el-tabs>
      </el-row>

      <!--              <span class="text_3 text_5">行业资讯</span>-->
      <!--              <span class="text_3 text_6">服务公告</span>-->
      <!--              <span class="text_3 text_7">小妙招</span>-->
    </div>
    <div class="flex-col group_2">
      <div v-for="item in list">
        <div class="flex-row">
          <div class="flex-row group_4">
            <img :src="item.cover" class="image image_1" />
            <div class="flex-col group_5">
              <div class="flex-col items-start" @click="jumpDetails(item.id)">
                <span class="text_3 text_8">{{ item.title }}</span>
                <span class="text_3 text_9">{{ item.abstractInfo }}</span>
              </div>
              <span class="text_3 text_10">{{ item.creatDate }}</span>
            </div>
          </div>
          <span class="text_3 text_11">
            <img
              src="https://project-user-resource-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16545673877397601883.png" />
            {{ item.viewNum }}
          </span>
        </div>
        <br>
      </div>

      <div class="justify-center group_16">
        <el-button type="primary" @click="changePage(1)">首页</el-button>
        <el-pagination @current-change="changePage" background layout="prev, pager, next"
          :current-page="pageInfo.current" :page-count="pageInfo.pages">
        </el-pagination>
        <el-button type="primary" @click="changePage(pageInfo.pages)">尾页</el-button>
      </div>

      <div class="flex-col items-start group_19">
        <span class="text_3 text_26">CONTACT US</span>
        <span class="text_3 text_27">联系我们</span>
      </div>

      <div class="justify-between group_20 view_3">
        <div class="flex-row group_21">
          <div class="flex-row">
            <div class="flex-col group_23">
              <div class="flex-col group_20">
                <div class="flex-row">
                  <img
                    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16546743881633275653.png"
                    class="image_10" />
                  <span class="text_3 text_28">客户服务</span>
                </div>
                <span class="text_3 text_29">400-6655-888</span>
              </div>
              <div class="flex-col group_25">
                <div class="flex-row">
                  <img
                    src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16546743881650382748.png"
                    class="image_11" />
                  <span class="text_3 text_30">人才招聘</span>
                </div>
                <span class="text_31">zhaopin@xiaoyujia.com</span>
              </div>
            </div>
            <div class="flex-col group_27">
              <div class="section_2"><!--*--></div>
              <div class="section_2 view_6"><!--*--></div>
            </div>
          </div>
          <div class="flex-col group_28">
            <div class="flex-col">
              <div class="flex-row group_20">
                <img
                  src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16546743881662665920.png"
                  class="image_12" />
                <span class="text_3 text_32">品牌合作</span>
              </div>
              <span class="text_33">franchise@xiaoyujia.com</span>
            </div>
            <div class="flex-col group_30">
              <div class="flex-row">
                <img
                  src="https://codefun-proj-user-res-1256085488.cos.ap-guangzhou.myqcloud.com/629db8d65a7e3f03103d893a/629dbbab51588f0011f78b95/16546743881665905654.png"
                  class="image_13" />
                <span class="text_3 text_34">联系地址</span>
              </div>
              <span class="text_35">厦门市思明区人才中心A栋6楼601</span>
            </div>
          </div>
        </div>
        <div class="flex-col items-center group_32">
          <img src="https://xyj-public-static.obs.cn-east-3.myhuaweicloud.com/UISCK/wwwcode.png" class="image_14" />
          <span class="text_36">扫一扫 了解更多</span>
        </div>
      </div>
    </div>
    <div class="lh100 text-c">@1999-2025 www.xiaoyujia.com 厦门小羽佳家政股份有限公司 <a
        href="https://beian.miit.gov.cn/#/Integrated/index">闽ICP备11006005号-1</a></div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      activeIndex: 1,
      typeNameList: [],
      typeName: '服务公告',
      pageInfo: { total: 10, size: 10, current: 1, pages: 1 },
      model: {
        typeName: null,
        title: null,
        current: 1,
        size: 5
      },
      list: [],
    };
  },
  mounted() {
    document.title = "小羽佳-动态新闻";
    if (this.$route.query.typeName) {
      this.typeName = this.$route.query.typeName;
    }
    this.getTreeHead();
    this.getData();
  },
  methods: {
    changePage(e) {
      this.model.current = e;
      this.pageInfo.current = e;
      this.getData();
    },
    getTreeHead() {
      this.$getData("getTypeNameList", null).then(res => {
        if (res.status == 200) {
          this.typeNameList = res.data;
        } else {
          this.$message.error("查询失败，" + res.msg);
        }
      })
    },
    jumpDetails(val) {
      console.log(val)
      this.$router.push({
        name: "newsDetail",
        query: {
          id: val
        }
      })
    },
    changeTag() {
      this.model.current = 1;
      this.getData();
    },
    getData() {
      if (this.typeName == 'all') {
        this.model.typeName = null
      } else {
        this.model.typeName = this.typeName
      }
      this.$postData("cmsInfoPage", this.model, {}).then(res => {
        if (res.status == 200) {
          this.list = res.data.records;
          this.pageInfo.size = res.data.size;
          this.pageInfo.total = res.data.total;
          this.pageInfo.pages = res.data.pages;
        } else {
          this.$message.error("查询失败，" + res.msg);
        }
      })
    },
  },
};
</script>

<style scoped lang="css">
.text_3 {
  text-transform: uppercase;
}

.image {
  border-radius: 0.52vw;
  width: 20.56vw;
  height: 10.52vw;
}


.page {
  /* padding-bottom: 4.27vw; */
  background-color: #ffffff;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}

.section_1 {
  padding: 0.5vw 3.56vw 29.92vw;
  background-image: url('https://xyj-public-static.obs.cn-east-3.myhuaweicloud.com/publicImage/1739511979092wwwHome.png');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.group_1 {
  margin-left: 6.3vw;
  margin-top: 3.44vw;
  align-self: flex-start;
}

.group_2 {
  margin-top: 4.38vw;
  padding: 0 6.88vw;
}

.text-wrapper {
  margin-left: 0.16vw;
  margin-top: 2.66vw;
  padding: 0.52vw 0 0.47vw;
  background-color: #ffdd00;
  border-radius: 0.96vw;
  width: 6.82vw;
}


.text {
  color: #282828;
  font-size: 2.76vw;
  font-family: Source Han Sans CN;
  font-weight: 700;
  line-height: 2.66vw;
  letter-spacing: 0.21vw;
}

.text_1 {
  margin-top: 2.03vw;
  color: #ffcb05;
  font-size: 1.56vw;
  font-family: Source Han Sans CN;
  line-height: 1.46vw;
  letter-spacing: 0.12vw;
}

.text_2 {
  color: #ffffff;
  font-size: 1.04vw;
  font-family: Source Han Sans CN;
  line-height: 0.99vw;
  letter-spacing: 0.08vw;
}

.group_4 {
  flex: 1 1 auto;
}

.text_11 {
  margin: 14.32vw 4.64vw 0 2.08vw;
  color: #4b4b4b;
  font-size: 1.11vw;
  font-family: Source Han Sans CN;
  line-height: 1.2vw;
}

.image_1 {
  flex-shrink: 0;
}

.group_5 {
  margin: 0.31vw 0 0.47vw 1.56vw;
  flex: 1 1 auto;
}


.text_10 {
  margin-left: 2.55vw;
  margin-top: 8.13vw;
  align-self: flex-start;
  color: #4b4b4b;
  font-size: 1.11vw;
  font-family: Source Han Sans CN;
  line-height: 1.46vw;
}


.text_8 {
  color: #282828;
  font-size: 1.58vw;
  font-family: Source Han Sans CN;
  font-weight: 500;
  line-height: 1.82vw;
}

.text_9 {
  margin-top: 1.58vw;
  color: #4b4b4b;
  font-size: 1.11vw;
  font-family: Source Han Sans CN;
  line-height: 1.46vw;
}

.group_16 {
  margin-top: 4.84vw;
  align-self: center;
  width: 46.15vw;
}


.text_3 {
  text-transform: uppercase;
}

.group_19 {
  margin-top: 5.1vw;
  align-self: flex-start;
}

.text_26 {
  color: #ffcb05;
  font-size: 1.88vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.46vw;
  letter-spacing: 0.075vw;
}

.text_27 {
  margin-top: 0.68vw;
  color: #ffcb05;
  font-size: 1.15vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.09vw;
}

.text_3 {
  text-transform: uppercase;
}

.group_20 {
  padding: 0 0.26vw;
}

.section_2 {
  background-color: #939598;
  height: 6.93vw;
}

.view_3 {
  margin-top: 3.13vw;
}

.group_21 {
  margin-top: 0.36vw;
}

.group_32 {
  margin-right: 13.02vw;
  margin-bottom: 0.47vw;
}

.group_28 {
  margin-left: 2.97vw;
  align-self: center;
  width: 19.22vw;
}

.image_14 {
  width: 12.19vw;
  height: 12.24vw;
}

.text_36 {
  margin-top: 1.41vw;
  color: #808285;
  font-size: 1.61vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.11vw;
  letter-spacing: 0.065vw;
}

.group_23 {
  align-self: center;
  width: 14.9vw;
}

.group_27 {
  margin-left: 1.98vw;
  width: 0.1vw;
}

.group_30 {
  margin-top: 4.06vw;
  padding-left: 0.1vw;
}

.group_25 {
  margin-top: 4.69vw;
}

.view_6 {
  margin-top: 1.41vw;
}

.text_33 {
  margin-top: 0.68vw;
  align-self: flex-start;
  color: #808285;
  font-size: 1.25vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.35vw;
}

.text_35 {
  margin-top: 0.73vw;
  color: #808285;
  font-size: 1.25vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.15vw;
}

.text_29 {
  margin-top: 0.68vw;
  align-self: flex-start;
  color: #808285;
  font-size: 1.25vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 0.99vw;
}

.text_31 {
  margin-top: 0.83vw;
  color: #808285;
  font-size: 1.25vw;
  font-family: Source Han Sans CN;
  font-weight: 300;
  line-height: 1.3vw;
}

.image_12 {
  width: 1.98vw;
  height: 1.98vw;
}

.text_32 {
  margin-left: 0.83vw;
  margin-top: 0.94vw;
  color: #282828;
  font-size: 1.25vw;
  font-family: Source Han Sans CN;
  line-height: 1.15vw;
}

.image_13 {
  margin-bottom: 0.16vw;
  width: 2.03vw;
  height: 2.45vw;
}

.text_34 {
  margin: 1.41vw 11.04vw 0 1.04vw;
  color: #282828;
  font-size: 1.25vw;
  font-family: Source Han Sans CN;
  line-height: 1.2vw;
}

.image_10 {
  width: 2.19vw;
  height: 2.29vw;
}

.text_28 {
  margin-left: 0.78vw;
  margin-top: 1.09vw;
  color: #282828;
  font-size: 1.25vw;
  font-family: Source Han Sans CN;
  line-height: 1.2vw;
}

.image_11 {
  width: 2.71vw;
  height: 2.29vw;
}

.text_30 {
  margin: 1.09vw 6.88vw 0 0.31vw;
  color: #282828;
  font-size: 1.25vw;
  font-family: Source Han Sans CN;
  line-height: 1.2vw;
}

.tabs {
  width: 90vw;
}
</style>
